<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col
        xl="5"
        md="6"
      >
        <v-card :loading="loading.lab_result">
          <v-card-title class="d-flex justify-space-between">
            <div>{{ $tc('Lab Result') }} <span v-if="lab_result.external_id">{{ lab_result.external_id }}</span></div>
            <client-icon
              v-if="lab_result.client && $auth.check({ clients: ['*', 'view','edit'] })"
              :key="lab_result.client.uuid"
              :icon="lab_result.client.icon"
              :name="lab_result.client.name"
            />
          </v-card-title>
          <v-card-text>
            <lab-result
              :lab-result="lab_result"
              :loading="loading.lab_result"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="lab_result.status === 'complete' && lab_result.interpretation"
        xl="4"
        lg="5"
        md="6"
      >
        <v-card
          :loading="loading.pdf"
          min-height="600"
        >
          <v-skeleton-loader
            :loading="loading.pdf"
            type="image"
          >
            <v-responsive>
              <pdf
                ref="pdf"
                :src="pdf.src"
                :page="pdf.page"
                @num-pages="pdf.pages = $event"
              />
            </v-responsive>
            <v-btn
              v-if="pdf.pages > 1"
              :disabled="pdf.page === 1"
              fab
              top
              left
              absolute
              x-small
              class="mt-2"
              @click="pdf.page--"
            >
              <v-icon
                small
                class="ml-n1 mt-1"
              >
                fas fa-fw fa-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              v-if="pdf.pages > 1"
              :disabled="pdf.page === pdf.pages"
              fab
              top
              left
              absolute
              x-small
              class="mt-2 ml-9"
              @click="pdf.page++"
            >
              <v-icon
                small
                class="mt-1"
              >
                fas fa-fw fa-chevron-right
              </v-icon>
            </v-btn>
            <v-btn
              color="blue darken-2"
              fab
              top
              right
              absolute
              dark
              small
              class="mt-3"
              :href="pdf.src"
              :download="fileName"
            >
              <i class="fas fa-file-download fa-lg" />
            </v-btn>
          </v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    metaInfo () {
      return {
        title: this.$tc('Lab Result') + ' ' + (this.lab_result.external_id ?? ''),
      }
    },
    components: {
      LabResult: () => import('@/components/lab_result/LabResult.vue'),
      pdf: () => import('vue-pdf'),
    },
    data: () => ({
      loading: {
        lab_result: false,
        button: false,
        pdf: false,
      },
      valid: true,
      lab_result: {},
      pdf: {
        src: null,
        page: 1,
        pages: null,
      },
    }),
    computed: {
      fileName () {
        var filename
        if (this.lab_result.external_id) {
          filename = this.lab_result.external_id + ' - ' + this.lab_result.sample_type + ' - ' + this.lab_result.patient.first_name + ' ' + this.lab_result.patient.last_name + ' - Arkstone.pdf'
        }
        return filename
      },
    },
    created () {
      this.fetchLabResult()
    },
    methods: {
      fetchLabResult () {
        this.loading.lab_result = true
        const promise = this.axios.get('lab_results/' + this.$route.params.uuid)

        return promise.then((response) => {
          this.lab_result = response.data
          if (this.lab_result.status === 'complete' || this.lab_result.interpretation) {
            this.fetchPdf()
          }
        })
          .catch(error => {
            // If 404 redirect back to lab results page
            if (error.response?.status === 404) {
              this.$router.push({ name: 'Lab Results' })
            } else if (error.response?.data?.message) {
              this.$toast.error(error.response.data.message)
            }
          })
          .finally(() => { this.loading.lab_result = false })
      },
      fetchPdf () {
        this.loading.pdf = true
        this.axios.get('/lab_results/' + this.$route.params.uuid + '/interpretation/pdf', {
          params: {
            output: 'base64',
            redacted: !this.$auth.check({ patients: 'phi' }),
          },
        })
          .then((res) => {
            this.pdf.src = 'data:application/pdf;base64,' + res.data.report_content
          })
          .catch(error => {
            if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
          })
          .finally(() => { this.loading.pdf = false })
      },
    },
  }
</script>
